<template>
  <section class="m-setting-clause m-scroll">
    <!-- 头部导航 -->
    <m-header title="条款" ></m-header>
    <div class="main">
        <p>
            网络支付与安全协议
        </p>
        <p>
            <strong><span>我们采集客户的哪些个人信息并如何处理？</span></strong>
        </p>
        <p>
            <span>当你通过本网站下订单时，需要提供你的名字，电子邮件，电话号码，地址和信用卡的信息。此信息允许我们成功处理并完成您的订单啊，这也有助于我们维护您的帐户。</span>
        </p><br />
        <p>
            <strong><span>MC</span></strong><strong><span>国际企业有限公司如何保护您的信息？</span></strong>
        </p>
        <p>
            <span>在信息传输过程中，我们通过使用网络安全层软件（SSL）对您输入的信息进行加密，从而保护您的信息的安全。除此之外，对于我们收集的任何客户的敏感数据，我们会采取多层保护以防止任何未经授权的使用。</span>
        </p><br />
        <p>
            <strong><span>付款信息</span></strong>
        </p>
        <p>
            <span>在线支付服务由Bambora有限公司和SnapPay提供，使用256位扩展验证证书(EV)SSL事务加密。客户的支付信息将安全地传送给收款银行、信用卡和支付公司，以便进行实时交易。MC国际企业有限公司将不会在我们的服务器上存储任何信用卡信息。</span>
        </p><br />
        <p>
            <strong><span>什么是Cookies？</span></strong>
        </p>
        <p>
            <span>“Cookies”是由浏览器存储在计算机硬盘上的一小部分信息。我们的cookies不包含任何个人识别信息。<span class="white">当您使用我们的网站或客户端提供的产品或服务时，我们会自动收集您对我们服务的详细使用情况，作为有关网络日志保存。</span>大多数网络制造商会自动接受cookies，但您通常可以通过改变浏览器以防止这种情况发生。您不需要启用cookie来使用该网站，该网站使用存储在浏览器的内存中的“session cookies”以便您直接登录。</span>
        </p><br />
        <p>
            <strong><span>你的准许</span></strong>
        </p>
        <p>
            <span>使用我们的网站，即代表您同意MC国际企业有限公司收集和使用这些信息。若我们决定修改我们的隐私条款，我们将在隐私条款页面上发布这些更改，以便于您知道我们收集了什么信息，我们如何使用它，以及在什么情况下我们会披露它。</span>
        </p><br />
        <p>
            <strong><span>支付网关信息</span></strong>
        </p>
        <p>
            <span>我们现有的付款选择有限但安全可靠。我们为所有注册用户提供信用卡付款。信用卡付款直接通过SSL加密软件使用，以确保您的个人信息安全。您的支付信息不会存储以确保支付安全。</span>
        </p>
    </div>
  </section>
</template>

<script>
import MHeader  from '@/components/zh/m-header.vue'

export default {
  name:'Clause',
  components:{ MHeader },
  data(){
    return {
      
    }
  }
}
</script>

<style lang="less" scoped>
.m-setting-clause {
  width: 100%;
  height: 100%;
  background-color: #F5F9FF;
  padding-top:44px;
  overflow-y: auto;
  .main {
    margin-top: 12px;
    width: 100%;
    padding: 0 16px;
    font-size: 16px;
    >p:nth-child(1) {font-size:19px;text-align: center;font-size: 30px;margin-bottom:50px;font-weight: 600;line-height: 40px;}
    .white{color:black;background:white}
  }
}
</style>